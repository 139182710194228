.Modal-content{
    background:var(--primary-blue);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: var(--font-avenir);
    padding: 10px;
    width :40%;
    height:60%
}
/* .Modal-content img{
    color:
} */
.modal-setting{
  
}
.btn-modal{
    font-family: var(--font-avenir);
    margin: 26px auto 0;
    border-radius: 15px;
    display: table;
    padding: 8px 30px;
    font-size: 15px;
    background: #fff;
    border:none

}
.otp-Input input {
    width: 40px !important;
    text-align: center;
    border: 1px solid #ededed;
    height: 34px;
    font-family: var(--font-avenir);
    font-size: 15px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.otp-Input input:focus-visible{
   outline: #ededed !important;
}
.heading{
    font-size: 28px;
    color:white
}

.modal_equipment .input-fields {
    padding: 0px;
}
.modal_equipment .heading {
    width: 100%;
}
.modal_equipment .equipment {
    margin: 0px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.modal_equipment .equipment .input {
    flex: 0 0 50%;
    width: 50%;
    margin: 0;
}

.modal_equipment label.equipment-id {
    margin-bottom: 10px;
}

.modal_equipment .category-parent {
    width: 50%;
}
.modal_equipment .category {
    top: 9px;
}
.modal_equipment .air-conditioner {
    top: 33px;
}

@media (max-width:767px){
    .modal_equipment.MuiBox-root.css-1wnsr1i {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
    }
    .modal_equipment .equipment .input {
        flex: 0 0 100%;
        width: 100%;
    }
    .modal_equipment .image_equipment {
        flex-wrap: wrap!important;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        justify-content: center;
        padding-left: 0px;
        row-gap: 20px;
    
    }
    .modal_equipment .category-parent {
        width: 100%;
    }
    .modal_equipment .header {
        gap: 0px;
    }
    .modal_equipment .buttonlight-purple1 {
        width: 100%;
        max-width: 200px;
    }
    .modal_equipment .footer {
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        row-gap: 20px;
    }
    .modal_equipment  .buttonlight-purple-parent {
        flex-wrap: wrap;
        width: 100%;
        row-gap: 18px;
    }
    .modal_equipment .deleteButton ,
    .modal_equipment button.addButton{
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }
    .modal_equipment button.buttonlight-purple {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }
    }
    .box-title{
        font-size :15px;
        color:#000;
        margin: 5px 0 5px 0;
    }