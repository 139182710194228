.top-section {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 0 var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.upcoming-bookings {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Avenir-medium';
  cursor: pointer
}
.span {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 30px;
  color: var(--base-grey-50);
}
.upcoming-bookings-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.upcomming-bookings {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonlight-purple-backgroun {
  position: relative;
  width: 96px;
  height: 30px;
  flex-shrink: 0;
}
.button {
  position: relative;
  font-size: var(--small-12px-regular-size);
  line-height: 24px;
  font-family: var(--font-avenir);
  color: var(--color-blueviolet-100);
  text-align: center;
  white-space: nowrap;
}
.button-wrapper {
  height: 24px;
  flex-direction: row;
  margin-left: -57px;
}
.button-wrapper,
.buttonlight-purple{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page,
.right-side-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.right-side-content {
  flex: 1;
  height: auto;
  padding: var(--padding-39xl) var(--padding-31xl) 20px;
  box-sizing: border-box;
}
.page {
  align-self: stretch;
  flex-direction: row;
}
.dashboard-1 {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--rowner-color);
  font-family: var(--font-avenir);
  margin-left: 300px;
}
.dashboard-1.subscript{
  justify-content: unset;
}
/* .dashboard-1.equip{
  flex-direction: unset;
} */
.select-input{
  margin : 0 0 15px 0
}
.select-input:focus-visible {
  outline: none;
}
@media screen and (max-width: 960px) {
  .top-section {
    flex-direction: column;
  }
  .right-side-content {
    padding-left: var(--padding-11xl);
    padding-top: var(--padding-16xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .page {
    flex-direction: column;
  }
}
@media screen and (max-width: 420px) {
  .right-side-content,
  .top-section {
    flex-direction: column;
  }
  .right-side-content {
    padding-left: var(--padding-3xl);
    padding-top: 0;
    padding-right: var(--padding-3xl);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .page {
    width: 100%;
    flex-direction: column;
    max-width: 100%;
  }
}

@media (max-width: 1200px){
  .dashboard-1 {
    width: 100%;
    margin-left:auto
  }
}
