.vector-icon5 {
  position: relative;
  width: auto;
  height: auto;
  flex-shrink: 0;
  padding: 0 0 0 var(--padding-36xl);
}
.sidebar-w100{
  background: #002147;
  position: fixed;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-y: hidden;
}

.side-footer
{
  position: absolute !important;
  bottom: 0px;
  left: 0;
  right: 0;
}
.logo {
  cursor: pointer;
}
.name {
  position: relative;
  line-height: 24px;
  font-family: 'Avenir-medium';
}
.shape-icon {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.badgesnumberfill17px,
.number {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.number {
  position: relative;
  letter-spacing: 0.3px;
  line-height: 16px;
  font-weight: 900;
  justify-content: center;
  font-family: 'Avenir-black';
  width: 13.76px;
  height: 13px;
  margin-top: -16px;
}
.badgesnumberfill17px {
  width: 18px;
  height: 18px;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--neutral-white);
}
.link-01,
.link-02,
.link-3,
.name-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.name-parent {
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.link-01,
.link-02,
.link-3 {
  cursor: pointer;
  text-decoration: none;
}
.link-01 {
  align-items: flex-start;
  color:#fff;
}
.link-02,
.link-3 {
  align-items: center;
  color: inherit;
}
.link-3 {
  justify-content: center;
}
.link-03,
.links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.link-03 {
  cursor: pointer;
  text-decoration: none;
  flex-direction: row;
  color: inherit;
}
.links {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-53xl) 0 var(--padding-413xl) var(--padding-36xl);
  gap: var(--gap-13xl);
}
.selected{
  color:#AEAEE8 !important
}
.icon-left-copy-9 {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 24px;
}
.bruce-lee,
.guru-member {
  margin: 0;
  position: absolute;
  left: 88px;
  font-family: inherit;
  display: inline-block;
  width: 139px;
}
.bruce-lee {
  top: 27px;
  font-size: inherit;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
}
.guru-member {
  top: 47px;
  font-size: var(--small-12px-regular-size);
  line-height: 21px;
  font-weight: 400;
  font-family: 'Avenir-regular';
  color: var(--black-500-gray);
}
.side-footer {
  position: relative;
  background-color: rgb(250 250 251 / 2%);
  width: 100%;
  height: 96px;
  flex-shrink: 0;
  font-family: var(--small-12px-regular);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.menu-sidebar {
  background-color: var(--custom-blue);
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-26xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--caption-14px-medium-size);
  color: var(--neutral-white);
  font-family: var(--font-avenir);
}
@media screen and (max-width: 960px) {

  .links {
    padding-top: var(--padding-51xl);
    padding-bottom: var(--padding-181xl);
    box-sizing: border-box;
  }
  .menu-sidebar {
    width: 100%;
    height: 700px;
    max-width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .vector-icon5 {
    padding: 0;
}
  .links,
  .logo {
    padding-left: var(--padding-6xl);
    box-sizing: border-box;
  }
  .links {
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-81xl);
  }
  .menu-sidebar {
    width: 100%;
    height: 600px;
    padding-top: var(--padding-xl);
    box-sizing: border-box;
    max-width: 100%;
  }
}



/* Custom CSS here */
  .hamburger{
    display: none;
  }
@media (max-width: 1200px){
  
  .menu-sidebar-parent {
    flex-wrap: wrap;
}
  .sidebar_main{
    position: fixed;
    top: 0;
    left: 0;
    transition: 1s ease;
    height: 100%;
    z-index: 999;
    width: 250px;
    overflow-y: hidden;
    bottom: 0;
  }
  .custom-ham {
    background: transparent;
    border: none;
    padding: 0;
    margin: 60px 57px 0;
  }
  .custom-ham svg
  {
    font-size: 33px;
  }
  .right-side-content1{
    padding-top: 24px !important;
  }
  .menu-sidebar {
    width: 100%;
    height: 100%;
  }
  .page{
    flex-wrap: wrap;
  }
  .side-footer {
    left: 0;
    position: absolute;
    bottom: 0;
}
.guru-member{
  top: 47px;
    font-size: var(--small-12px-regular-size);
    line-height: 21px;
    font-weight: 400;
    font-family: 'Avenir-regular';
    color: var(--black-500-gray);
    overflow: hidden;
    text-overflow: ellipsis;
}
}

@media (max-width: 960px){

  .custom-ham
  {
    margin: 30px 30px 0;
  }
}
.logout_icon.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{

    top: 513px !important;
    left: 122px !important;
}

@media (max-width:991px){
  .sidebar_main .links{
    max-height: calc(100vh - 166px);
    overflow: auto;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1201px) {

  .dashboard-1{
    width:100% !important
  }
}