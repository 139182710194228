@import url("https://fonts.googleapis.com/css2?family=Avenir:wght@400;500;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Avenir+LT+Std:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@font-face{
  font-family: 'Avenir-medium';
  font-weight: 500; 
  src: url('../fonts/AvenirMedium.eot') format('embedded-opentype'),
  url('../fonts/AvenirMedium.ttf') format('truetype'),
  url('../fonts/AvenirMedium.woff') format('woff'),
  url('../fonts/AvenirMedium.woff2') format('woff2');

}
@font-face{
  font-family: 'Avenir-black';
  font-weight: 900; 
  src: url('../fonts/AvenirBold.eot') format('embedded-opentype'),
  url('../fonts/AvenirBold.ttf') format('truetype'),
  url('../fonts/AvenirBold.woff') format('woff'),
  url('../fonts/AvenirBold.woff2') format('woff2');
}
@font-face{
  font-family: 'Avenir-regular';
  font-weight: 400; 
  src: url('../fonts/AvenirLight.eot') format('embedded-opentype'),
  url('../fonts/AvenirLight.ttf') format('truetype'),
  url('../fonts/AvenirLight.woff') format('woff'),
  url('../fonts/AvenirLight.woff2') format('woff2');
}
body {
  margin: 0;
  line-height: normal;
  font-family: 'Avenir-medium';
}
:root {
  /* fonts */
  --font-avenir: Avenir;
  --font-avenir-lt-std: "Avenir LT Std";
  --small-12px-regular: "Open Sans";
  --font-poppins: Poppins;
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 16px;
  --small-12px-regular-size: 12px;
  --font-size-lg: 18px;
  --font-size-3xl: 22px;
  --caption-14px-medium-size: 14px;
  --font-size-31xl: 50px;
  --font-size-3xs: 10px;
  --font-size-base-1: 15.1px;

  /* Colors */
  --color-gray-200: #1F1F39;
  --color-slategray: #78789d;
  --color-gainsboro-100: #d9d9d9;
  --color-darkslateblue-100: rgba(14, 76, 149, 0.1);
  --custom-blue: #002147;
  --custom-blue1: #002A73;
  --forecolor1: #8181D7;
  --neutral-white: #fff;
  --color-whitesmoke: #f6f6f6;
  --color-blueviolet-100: #583ef2;
  --forecolor: #583ef2;
  --color-blueviolet-200: rgba(88, 62, 242, 0.2);
  --base-grey-50: #939699;
  --color-gray-300: rgba(0, 0, 0, 0.1);
  --color-gray-100: #fafafb;
  --black-500-gray: #807e7e;
  --rowner-color: #202034;
  --black-800: #212121;
  --primary-blue: #002147;
  --color-lavender: #eaeaff;
  --color-darkslateblue: #38385e;
  --base-grey-85: #4b5157;
  --color-darkslategray-100: #373737;
  --color-azure: #ecfcff;
  --color-lavenderblush: #ffebf0;
  --color-black: #000;
  --base-grey-15: #d9dadb;
  --color-palevioletred: rgba(247, 101, 139, 0.2);

  /* Gaps */
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-13xl: 32px;
  --gap-2xs: 11px;
  --gap-8xs: 5px;
  --gap-mini: 15px;
  --gap-2xl: 21px;

  /* Paddings */
  --padding-39xl: 58px;
  --padding-31xl: 50px;
  --padding-11xl: 30px;
  --padding-16xl: 35px;
  --padding-126xl: 145px;
  --padding-xs: 12px;
  --padding-3xl: 22px;
  --padding-6xl: 25px;
  --padding-5xl: 24px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-19xl: 38px;
  --padding-18xl: 37px;
  --padding-26xl: 45px;
  --padding-95xl: 114px;
  --padding-413xl: 432px;
  --padding-36xl: 55px;
  --padding-51xl: 70px;
  --padding-181xl: 200px;
  --padding-81xl: 100px;
  --padding-10xs: 3px;
  --padding-mini: 15px;
  --padding-sm: 14px;
  --padding-10xs: 3px;
  --padding-53xl: 74px;


  /* border radiuses */
  --br-3xs: 10px;
  --br-5xs: 8px;
  --br-mini: 15px;
  --br-xs: 12px;
  --br-31xl: 50px;
  --br-14: 14px;
  --br-xl: 20px;
  --br-6xl: 25px;
  --br-sm: 14px;
}

