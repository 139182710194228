.vector-icon1 {
  position: relative;
  width: 17.16px;
  height: auto;
  flex-shrink: 0;
}
.sign-in {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.7px;
  line-height: 70px;
  font-weight: 300;
  font-family: 'Avenir-regular';
  display: inline-block;
}
.sign-in.small{
  font-size: smaller !important;
}
.sign-header,
.sign-in,
.sign-in-now {
  align-self: stretch;
  flex-shrink: 0;
}
.sign-in-now {
  margin: 0;
  position: relative;
  font-size: var(--caption-14px-medium-size);
  line-height: 24px;
  font-weight: 300;
  font-family: 'Avenir-regular';
  display: inline-block;
  height: auto;
}
.sign-header {
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.cta,
.remember-checkbox {
  position: relative;
}
.cta {
  flex: 1;
  font-size: var(--font-size-lg);
  line-height: 32px;
  font-family: 'Avenir-regular';
  font-weight: 400; 
  color: var(--primary-blue);
  text-align: center;
}
.button-variant {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.button-variant {
  cursor: pointer;
  border: 0;
  padding: 8px 47px;
  background-color: var(--neutral-white);
  border-radius: var(--br-10xs);
  width: 100%;
  height: 45.47px;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
}
.button-variant.forgot{
  margin-top: 10px                                 ;

}
.forgot-password {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  line-height: 28px;
  color: inherit;
  display: inline-block;
  width: 100%;
  font-family: 'Avenir-regular';
  font-weight: 400; 
}
.sign-footer {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--font-size-base);
}
.content1,
.sign-footer,
.signin,
.signin-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content1 {
  align-self: stretch;
  align-items: center;
  gap: 41px;
}
.signin,
.signin-page {
  align-items: flex-start;
}
.signin-page {
  align-self: stretch;
  width: 30%;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.signin {
  position: relative;
  background-color: var(--primary-blue);
  width: 100%;
  overflow: hidden;
  padding: 75px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 60px;
  color: var(--neutral-white);
  font-family: var(--font-avenir);
  height: 100vh;
}
.signin .txt {
  color: #807e7e;
  font-weight: 400;
  font-family: 'Avenir-regular';
  font-size: 14px;
  line-height: 24px;
  border: 1px solid rgba(196, 196, 196, 0.2);
  border-radius: 3px;
  padding: 12px 16px;
  background: transparent;
  width: 100%;
  margin-bottom: 2px;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333 !important;
}
input:-webkit-autofill::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent !important;
  z-index: 1;
}



.signin .txt::placeholder {
  color: #807e7e;
}
.signin .form-group {
  width: 100%;
  position: relative;
  padding-bottom: 8px;
}
.signin .form-group.email .txt {
  padding: 12px 16px 12px 43px;
}
.signin .form-group.email:before {
  content: "";
  background: url("../../../assets/svg/icon.svg");
  background-repeat: no-repeat;
  background-size: 17px;
  height: 17px;
  width: 17px;
  position: absolute;
  top: 17px;
  left: 17px;
}
.checkbox-lbl label {
  color: #ffffff;
  font-weight: 400;
  font-family: 'Avenir-regular';
  font-size: 16px;
  line-height: 28px;
  margin-left: 0;
}
.signin .form {
  width: 100%;
  display:block
}

.checkbox-lbl {
  display: flex;
  margin-bottom: 36px;
  position: relative;
}

.checkbox-lbl input {
  padding: 0;
  margin-bottom: 0;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 24px;
  opacity: 0;
}

.checkbox-lbl label {
  position: relative;
  cursor: pointer;
}

.checkbox-lbl label:before {
  content: "";
  -webkit-appearance: none;
  background-color: #0e4c95;
  border: 2px solid #0e4c95;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50%;
}

.checkbox-lbl input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 7px;
  height: 14px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.errors{
  color: red;
  font-size: 13px;
  display: block;
  text-align: center;
  font-family: 'Avenir-regular';
  font-weight: 400;
}
.errors::before {
  display: inline;
  content: "⚠ ";
}

@media (max-width: 960px) {
  .signin-page {
    width: 81%;
  }
}
